import { RealtimeClient } from '@openai/realtime-api-beta';
import { getShipments, getCompliances, getApplicableCompliancesForDestination, getSupplierPolicyDocuments, getPolicyDocumentForCompliance, getSuppliersForShipmentByShipmentId } from './supplychain';

export const addClientTools = (client: RealtimeClient, setMemoryKv: (callback: (prevKv: { [key: string]: any }) => { [key: string]: any }) => void) => {
  // Add set_memory tool
  client.addTool(
    {
      name: 'set_memory',
      description: 'Saves important data about the user into memory.',
      parameters: {
        type: 'object',
        properties: {
          key: {
            type: 'string',
            description:
              'The key of the memory value. Always use lowercase and underscores, no other characters.',
          },
          value: {
            type: 'string',
            description: 'Value can be anything represented as a string',
          },
        },
        required: ['key', 'value'],
      },
    },
    async ({ key, value }: { [key: string]: any }) => {
      setMemoryKv((memoryKv) => {
        const newKv = { ...memoryKv };
        newKv[key] = value;
        return newKv;
      });
      return { ok: true };
    }
  );

  // Add get_shipments tool
  client.addTool(
    {
      name: 'get_shipments',
      description: 'Get a list of all shipments in the system',
      parameters: {
        type: 'object',
        properties: {},
        required: [],
      },
    },
    async () => {
      console.log('get_shipments');
      const result = getShipments();
      console.log(result);
      return result;
    }
  );

  // Add get_compliances tool
  client.addTool(
    {
      name: 'get_compliances',
      description: 'Get a list of all compliance requirements',
      parameters: {
        type: 'object',
        properties: {},
        required: [],
      },
    },
    async () => {
      console.log('get_compliances');
      const result = getCompliances();
      console.log(result);
      return result;
    }
  );

  // Add get_applicable_compliances_for_destination tool
  client.addTool(
    {
      name: 'get_applicable_compliances_for_destination',
      description: 'Get a list of compliance requirements applicable for a specific destination',
      parameters: {
        type: 'object',
        properties: {
          destination: {
            type: 'string',
            description: 'The destination location to check compliances for',
          },
        },
        required: ['destination'],
      },
    },
    async ({ destination }: { destination: string }) => {
      console.log('get_applicable_compliances_for_destination', destination);
      const result = getApplicableCompliancesForDestination(destination);
      console.log(result);
      return result;
    }
  );

  // Add get_supplier_policy_documents tool
  client.addTool(
    {
      name: 'get_supplier_policy_documents',
      description: 'Get a list of policy documents for a specific supplier',
      parameters: {
        type: 'object',
        properties: {
          supplierId: {
            type: 'number',
            description: 'The ID of the supplier',
          },
        },
        required: ['supplierId'],
      },
    },
    async ({ supplierId }: { supplierId: number }) => {
      console.log('get_supplier_policy_documents', supplierId);
      const result = getSupplierPolicyDocuments(supplierId);
      console.log(result);
      return result;
    }
  );

  // Add get_policy_document_for_compliance tool
  client.addTool(
    {
      name: 'get_policy_document_for_compliance',
      description: 'Get the policy document for a specific compliance requirement',
      parameters: {
        type: 'object',
        properties: {
          complianceName: {
            type: 'string',
            description: 'The name of the compliance requirement',
          },
        },
        required: ['complianceName'],
      },
    },
    async ({ complianceName }: { complianceName: string }) => {
      console.log('get_policy_document_for_compliance', complianceName);
      const result = getPolicyDocumentForCompliance(complianceName);
      console.log(result);
      return result;
    }
  );

  // Add get_suppliers_for_shipment tool
  client.addTool(
    {
      name: 'get_suppliers_for_shipment',
      description: 'Get a list of suppliers associated with a specific shipment',
      parameters: {
        type: 'object',
        properties: {
          shipmentId: {
            type: 'number',
            description: 'The ID of the shipment',
          },
        },
        required: ['shipmentId'],
      },
    },
    async ({ shipmentId }: { shipmentId: number }) => {
      console.log('get_suppliers_for_shipment', shipmentId);
      const suppliers = getSuppliersForShipmentByShipmentId(shipmentId);
      // Following the rule to only show names
      const result = suppliers.map(supplier => supplier.name);
      console.log(result);
      return result;
    }
  );

  // Add send_email tool
  client.addTool(
    {
      name: 'send_email',
      description:
        'Send email to the supplier stating the failed compliances',
      parameters: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'Name of the shipment',
          },
        },
        required: [],
      },
    },
    async () => {
      console.log('send_email');
      const result = await fetch('http://127.0.0.1:4000/api/send_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await result.json();
      console.log('Email send response:', response);
      return response;
    }
  );
};
