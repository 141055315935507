interface Shipment {
    id: number;
    name: string;
    ready_to_ship: boolean;
    start_location: string;
    destination: string;
    supplier_ids: number[];
}

interface Compliance {
    name: string;
}

interface Supplier {
    id: number;
    name: string;
    country: string;
}

interface PolicyDocument {
    name: string;
}

interface CompliancePolicy {
    compliance_name: string;
    policy_document: PolicyDocument;
}

/////////////// Data Structures ///////////////
export const shipments: Shipment[] = [
    {
        "id": 1,
        "name": "Bedsheet",
        "ready_to_ship": true,
        "start_location": "Mumbai",
        "destination": "Berlin",
        "supplier_ids": [1, 2]
    },
    {
        "id": 2,
        "name": "Bathrobe",
        "ready_to_ship": true,
        "start_location": "Mumbai",
        "destination": "Madrid",
        "supplier_ids": [1, 2]
    },
    {
        "id": 3,
        "name": "Towels",
        "ready_to_ship": false,
        "start_location": "Delhi",
        "destination": "Paris",
        "supplier_ids": [2, 3]
    }
];

const compliances: Compliance[] = [
    {
        "name": "No Child Labor"
    }, 
    {
        "name": "No Forced Labor"
    }, 
    {
        "name": "EUDR"
    }
];

const suppliers: Supplier[] = [
    {
        "id": 1,
        "name": "Super Duper Industries",
        "country": "China"
    },
    {
        "id": 2,
        "name": "Good Morning Limited",
        "country": "India"
    },
    {
        "id": 3,
        "name": "Hong Industries",
        "country": "Vietnam"
    }
];

const supplierPolicyDocuments = [
    {
        "supplier_id": 1,
        "policy_documents": [
            "Child Labor Policy",
            "EUDR Policy"
        ]
    },
    {
        "supplier_id": 2,
        "policy_documents": [
            "Child Labor Policy",
            "Forced Labor Policy"
        ]
    },
    {
        "supplier_id": 3,
        "policy_documents": [
            "Forced Labor",
            "EUDR Policy"
        ]
    }
]

const compliancePolicyDocuments: CompliancePolicy[] = [
    {
        "compliance_name": "No Child Labor",
        "policy_document": {"name": "Child Labor Policy"}
    },
    {
        "compliance_name": "No Forced Labor",
        "policy_document": {"name": "Forced Labor Policy"}
    },
    {
        "compliance_name": "EUDR",
        "policy_document": {"name": "EUDR Policy"}
    }
]

export const getShipments = (): Shipment[] => { return shipments; };

export const getCompliances = (): Compliance[] => { return compliances;};

export const getApplicableCompliancesForDestination = (destination: string): string[] => {
    if (destination === "Berlin") {
        return ["Child Labor Policy", "Forced Labor Policy"];
    } else if (destination === "Madrid") {
        return ["Forced Labor Policy", "EUDR Policy"];
    } else if (destination === "Paris") {
        return ["Child Labor Policy", "Forced Labor Policy", "EUDR Policy"];
    } else {
        return [];
    }
};

export const getSupplierPolicyDocuments = (supplierId: number): string[] => {
    console.log("called getSupplierPolicyDocuments with Supplier ID:", supplierId);
    const supplierDoc = supplierPolicyDocuments.find(doc => doc.supplier_id === supplierId);
    return supplierDoc?.policy_documents || [];
};

export const getPolicyDocumentForCompliance = (complianceName: string): PolicyDocument | null => {
    console.log("called getPolicyDocumentForCompliance with Compliance Name:", complianceName);
    const complianceDoc = compliancePolicyDocuments.find(doc => doc.compliance_name === complianceName);
    return complianceDoc?.policy_document || null;
};

export const getSuppliersForShipmentByShipmentId = (shipmentId: number): Supplier[] => {
    console.log("called getSuppliersForShipmentByShipmentId with Shipment ID:", shipmentId);
    const shipment = shipments.find(shipment => shipment.id === shipmentId);
    if (!shipment) {
        return [];
    }
    return suppliers.filter(supplier => shipment.supplier_ids.includes(supplier.id));
};